$primaryColor: #c8210f;
$lightPink: #fff8f7;
$offWhite: #eaeaea;
$offBlack: #383838;
$transparentWhite: #ffffffbf;
$successGreen: #a5f1a5;
$successGreenDark: #017e01;
$errorRedLight: #f88a8a;
$errorRed: #c10808;
$errorRedDark: #910101;
