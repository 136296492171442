.loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  img {
    width: 4rem;
    height: auto;
  }
}
