@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&display=swap');
@import './colors';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', 'san serif';
  font-weight: 400;
  overflow-x: hidden;
}

.btn-link {
  text-decoration: none !important;
}

.main-btn {
  cursor: pointer;
  border: 1px solid $primaryColor;
  background-color: $primaryColor;
  color: white;
  padding: 1rem 3rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition: 0.2s;
  &:hover {
    background-color: white;
    color: $primaryColor;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: $primaryColor;
      color: white;
    }
  }
}

.outline-btn {
  background-color: white;
  color: $primaryColor;
  &:hover {
    background-color: $primaryColor;
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: white;
      color: $primaryColor;
    }
  }
}

.slim-btn {
  padding: 0.8rem 2rem;
}
